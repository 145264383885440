import { useForm } from 'react-hook-form'
import './ContactForm.css'
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore"; 

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const { name, email, subject, message } = data

    try {
    	addDoc(collection(db, "contacts"), {
	name: name,
	email: email,
        subject: subject,
        message: message,
    });
    }
    catch(e) {
	alert(e.message)
    }

    alert("Thank you! Your message has been submitted.");
  }

  return (
    <div className="contact-form">
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Row 1 of form */}
        <h3
          style={{
            color: '#909090',
            fontFamily: 'Nunito',
            textAlign: 'center',
            marginTop: '0.5px',
          }}
        >
          Contact the Author
        </h3>
        <div className="row formRow">
          <div className="col-6">
            <input
              type="text"
              name="name"
              {...register('name', {
                required: {
                  value: true,
                  message: 'Please enter your name',
                },
                maxLength: {
                  value: 30,
                  message: 'Please use 30 characters or less',
                },
              })}
              className="form-control formInput"
              placeholder="Name"
            ></input>
            {errors.name && (
              <span className="errorMessage">{errors.name.message}</span>
            )}
          </div>
          <div className="col-6">
            <input
              type="email"
              name="email"
              {...register('email', {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              className="form-control formInput"
              placeholder="Email address"
            ></input>
            {errors.email && (
              <span className="errorMessage">
                Please enter a valid email address
              </span>
            )}
          </div>
        </div>
        {/* Row 2 of form */}
        <div className="row formRow">
          <div className="col">
            <input
              type="text"
              name="subject"
              {...register('subject', {
                required: {
                  value: true,
                  message: 'Please enter a subject',
                },
                maxLength: {
                  value: 75,
                  message: 'Subject cannot exceed 75 characters',
                },
              })}
              className="form-control formInput"
              placeholder="Subject"
            ></input>
            {errors.subject && (
              <span className="errorMessage">{errors.subject.message}</span>
            )}
          </div>
        </div>
        {/* Row 3 of form */}
        <div className="row formRow">
          <div className="col">
            <textarea
              rows={3}
              name="message"
              {...register('message', {
                required: true,
              })}
              className="form-control formInput"
              placeholder="Message"
            ></textarea>
            {errors.message && (
              <span className="errorMessage">Please enter a message</span>
            )}
          </div>
        </div>
        <button className="submit-btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  )
}

export default ContactForm
