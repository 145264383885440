import React, { useState } from 'react'
import { PageTextbox } from './Shared'


// From Ben - Apologies, Anneliese, I apologize for making such a hack of of this page.  I am trying to improve it in
// the NewsEntries.js file so that Jenn can add news entries on her own, but it is a work in progress currently :)
export const BlogText = () => {

  const rowSpacer = () => {
	return (
	 <div><br/></div>
        )
  }
  const unvVid = () => {
	return (
<div>
Unnatural Vegan: "Veganism & Eating Disorder Recovery Don't Mix? (Every Therapist NEEDS This Book!)" 
<div> <iframe width="560" height="315" src="https://www.youtube.com/embed/MJYb5mBZupg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div> 
</div>
	)
  }

 const udePodcast = () => {
        return (
<div>
Understanding Disordered Eating Podcast: <a href="https://pod.link/1580296341/episode/73c23c74ff5c672ec9d9b36f953581e4">"Veganism and Eating Disorders"</a>
</div>
)
 }

 const edotPodcast = () => {
	return (
<div>
Eating Disorders Off Topic Podcast: <a href="https://www.buzzsprout.com/826117/12384118">"Can I be Vegan in Treatment?"</a>
</div>
        )
  }

 const edvVid = () => {
	return (
<div>
Eating Disorders and Veganism: A Conversation:
<div>
<iframe width="560" height="315" src="https://www.youtube.com/embed/T9_2VQsXlWk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
</div>
        )
  }

const [news, setNews] = useState([
        { id: 1, date: 'June 14, 2023' , content: unvVid()},
        { id: 2, date: '' , content: rowSpacer()},
        { id: 3, date: 'June 13, 2023', content: udePodcast()},
        { id: 4, date: '', content: rowSpacer()},
        { id: 5, date: 'March 6, 2023', content: edotPodcast()},
        { id: 6, date: '', content: rowSpacer()},
        { id: 7, date: 'February 26, 2023', content: edvVid()},
    ]);

    return (
        <div>
 	<h3
        style={{
          color: '#909090',
          fontFamily: 'Nunito',
          textAlign: 'center',
          marginTop: '26px',
        }}
      >
	Welcome! - Jenn
      </h3>
 <PageTextbox>
      <table 
          style={{
            color: '#909090',
            fontFamily: 'Nunito',
          }}
      >
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    {news && news.map(news =>
                        <tr key={news.id}>
                            <td><b>{news.date}</b> {news.content}</td>
                        </tr>
                    )}
                </tbody>
            </table>
 </PageTextbox>
        </div>
  )
}
