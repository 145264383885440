import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8p-r_-aSXsSEtYtcO3SrLvQwuJ4NI-CY",
  authDomain: "veganism-book-project.firebaseapp.com",
  projectId: "veganism-book-project",
  storageBucket: "veganism-book-project.appspot.com",
  messagingSenderId: "432817267148",
  appId: "1:432817267148:web:82e4ad391c96a1d012bd69",
  measurementId: "G-9EV4G33EYC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
signInAnonymously(auth)
  .then(() => {
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };
