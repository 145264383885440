import React from 'react'
import Footer from '../components/Footer.js'
import { BlogText } from '../components/BlogText.js'
import { Container } from '@mui/material'

export const Blog = () => {
  return (
    <div>
      <Container fixed>
        <BlogText/>
        <Footer />
      </Container>
    </div>
  )
}
